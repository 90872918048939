"use client";

import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { GoogleSignInForm } from "../GoogleSignInForm";
import { AppleSignInForm } from "../AppleSignInForm";
import { useSignInPopoverStore } from "../../providers/SingInPopoverStoreProvider";
import { Steps } from "./steps";
import { SignInForm } from "./SignInForm";
import { MfaForm } from "./MfaForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { ResetPasswordVerificationForm } from "./ResetPasswordVerificationForm";
import { UpdatePasswordForm } from "./UpdatePasswordForm";
import { useCompanyData } from "@/hooks/useCompanyData";

type Props = {};

export const SignInPopover = ({}: Props) => {
  const { open, setOpen } = useSignInPopoverStore((state) => state);

  // For animation chaining
  const [show, setShow] = React.useState(false);

  const [step, setStep] = React.useState<(typeof Steps)[keyof typeof Steps]>(
    Steps.signIn
  );

  const [sessionID, setSessionID] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);

  // We need password temporarily for resend functionality
  const [password, setPassword] = React.useState<string | null>(null);

  // Reset password code
  const [resetCode, setResetCode] = React.useState<string | null>(null);

  const companyData = useCompanyData();

  const resetFlow = () => {
    setStep(Steps.signIn);
    setSessionID(null);
    setEmail(null);
    setPassword(null);
    setResetCode(null);
  };

  React.useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false);
      }, 300);
    }
  }, [open]);

  if (step === Steps.resetPassword) {
    return (
      <div
        className={clsx(
          `absolute overflow-auto max-h-[80vh] right-[0.38rem] top-full z-50 shadow-si border border-solid border-pn-black-base
          bg-pn-white-base px-5 pt-5 pb-[1.875rem] w-[28.5rem] transition-opacity duration-300`,
          open ? "opacity-100" : "opacity-0",
          show ? "flex" : "hidden"
        )}
      >
        <div className="flex flex-col space-y-5 justify-center items-start self-stretch h-full w-full">
          <h3 className="h1-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Reset password
          </h3>

          <p className="paragraph text-pn-black-base">
            Please enter the email address linked to your account
          </p>

          <ResetPasswordForm
            setEmail={setEmail}
            resetFlow={resetFlow}
            setStep={setStep}
          />
        </div>
      </div>
    );
  }

  if (step === Steps.resetPasswordVerification && email) {
    return (
      <div
        className={clsx(
          `absolute overflow-auto max-h-[80vh] right-[0.38rem] top-full z-50 shadow-si border border-solid border-pn-black-base
            bg-pn-white-base px-5 pt-5 pb-[1.875rem] w-[28.5rem] transition-opacity duration-300`,
          open ? "opacity-100" : "opacity-0",
          show ? "flex" : "hidden"
        )}
      >
        <div className="flex flex-col space-y-5 justify-center items-start self-stretch h-full w-full">
          <h3 className="h1-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Check your email inbox
          </h3>

          <p className="paragraph text-pn-black-base">
            We’ve sent an email to{" "}
            <strong className="paragraph-bold text-pn-black-base">
              {email}
            </strong>{" "}
            with a verification code
          </p>

          <ResetPasswordVerificationForm
            email={email}
            setStep={setStep}
            setCode={setResetCode}
          />
        </div>
      </div>
    );
  }

  if (step === Steps.updatePassword && email && resetCode) {
    return (
      <div
        className={clsx(
          `absolute overflow-auto max-h-[80vh] right-[0.38rem] top-full z-50 shadow-si border border-solid border-pn-black-base
              bg-pn-white-base px-5 pt-5 pb-[1.875rem] w-[28.5rem] transition-opacity duration-300`,
          open ? "opacity-100" : "opacity-0",
          show ? "flex" : "hidden"
        )}
      >
        <div className="flex flex-col space-y-5 justify-center items-start self-stretch h-full w-full">
          <h3 className="h1-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Create your new password
          </h3>

          <p className="paragraph text-pn-black-base">
            Set a password for your Photography News account:
            <strong className="paragraph-bold text-pn-black-base">
              {email}
            </strong>
          </p>

          <UpdatePasswordForm
            email={email}
            code={resetCode}
            resetFlow={resetFlow}
          />
        </div>
      </div>
    );
  }

  if (step === Steps.mfa && sessionID) {
    return (
      <div
        className={clsx(
          `absolute overflow-auto max-h-[80vh] right-[0.38rem] top-full z-50 shadow-si border border-solid border-pn-black-base
        bg-pn-white-base px-5 pt-5 pb-[1.875rem] w-[28.5rem] transition-opacity duration-300`,
          open ? "opacity-100" : "opacity-0",
          show ? "flex" : "hidden"
        )}
      >
        <div className="flex flex-col space-y-5 justify-center items-start self-stretch h-full w-full">
          <h3 className="h1-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Check your email inbox
          </h3>

          <p className="paragraph text-pn-black-base">
            We’ve sent an email to{" "}
            <strong className="paragraph-bold">{email}</strong> with a
            verification code
          </p>

          <MfaForm
            firstSessionId={sessionID}
            email={email!}
            password={password!}
            resetFlow={resetFlow}
            setOpenPopover={setOpen}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        `absolute overflow-auto max-h-[80vh] right-[0.38rem] top-full z-50 shadow-si border border-solid border-pn-black-base
        bg-pn-white-base px-5 pt-5 pb-[1.875rem] w-[28.5rem] transition-opacity duration-300`,
        open ? "opacity-100" : "opacity-0",
        show ? "flex" : "hidden"
      )}
    >
      <div className="flex flex-col space-y-5 justify-center items-start self-stretch h-full w-full">
        <h3 className="h1-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
          Sign in
        </h3>

        <p className="p-5 bg-pn-off-white-base small-print text-pn-black-base">
          By proceeding, you agree to our{" "}
          <a
            href={companyData?.company_data.terms_conditions_page?.url}
            target={companyData?.company_data.terms_conditions_page?.target}
            className="small-print-link"
          >
            terms and conditions
          </a>
          . For information about how we use your data, see our{" "}
          <a
            href={companyData?.company_data.privacy_policy_page?.url}
            target={companyData?.company_data.privacy_policy_page?.target}
            className="small-print-link"
          >
            privacy policy
          </a>
        </p>

        <GoogleSignInForm />
        <AppleSignInForm />

        <p className="small-print text-pn-grey-base">Or continue with</p>

        {/* Email Form */}
        <SignInForm
          setSessionID={setSessionID}
          setStep={setStep}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      </div>
    </div>
  );
};
