"use client";

import { IUser } from "@/types/User";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSWRConfig } from "swr";
import * as yup from "yup";
import React from "react";
import type { LoginResponse } from "./SignInForm";

type MfaFormProps = {
  firstSessionId: string;
  email: string;
  password: string;
  resetFlow: () => void;
  setOpenPopover: (open: boolean) => void;
};

type MfaFormValues = {
  mfaCode: string;
};

type MfaValidationResponse = {
  error?: string;
  user?: IUser;
};

const mfaSchema = yup.object({
  mfaCode: yup.string().required(),
});

export const MfaForm = ({
  firstSessionId,
  email,
  password,
  resetFlow,
  setOpenPopover,
}: MfaFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MfaFormValues>({
    mode: "onChange",
    resolver: yupResolver(mfaSchema),
  });

  const [sessionId, setSessionId] = React.useState<string>(firstSessionId);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [apiError, setApiError] = React.useState<string | null>(null);

  const { mutate } = useSWRConfig();

  const onSubmit: SubmitHandler<MfaFormValues> = async (data) => {
    setIsSubmitting(true);

    const mfaCodeEndpoint = `/api/auth/validateMfa`;

    try {
      const formData = new FormData();

      // Append each field to the FormData object
      formData.append("sessionId", sessionId);
      formData.append("mfaCode", data.mfaCode);

      const response = await fetch(mfaCodeEndpoint, {
        method: "POST",
        body: formData,
      });

      // We can store the response in a global state or context and local storage with expiry
      if (response.status < 400) {
        const mfaCodeResponse =
          (await response.json()) as MfaValidationResponse;

        // invalidate the cache
        mutate("/api/auth/me");

        setIsSubmitting(false);
        resetFlow();
        setOpenPopover(false);
      } else {
        console.error("Failed to validate MFA code");
        setApiError("Failed to validate MFA code");

        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);

      setIsSubmitting(false);
    }
  };

  const handleResend = async () => {
    const signInEndpoint = `/api/auth/sign-in-via-wp`;

    try {
      const formData = new FormData();

      // Append each field to the FormData object
      formData.append("email", email);
      formData.append("password", password);

      const response = await fetch(signInEndpoint, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const responseData = (await response.json()) as { error: string };
        setApiError(responseData.error);
        return;
      }

      const responseData = (await response.json()) as LoginResponse;
      setSessionId(responseData.sessionID);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-start space-y-5 w-full"
    >
      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="mfaCode" className="text-pn-black-base">
          MFA Code
        </label>
        <input
          type="text"
          id="mfaCode"
          {...register("mfaCode")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`,
          )}
          placeholder="Enter Code"
        />
        {errors.mfaCode && (
          <p className="text-pn-red-base">{errors.mfaCode.message}</p>
        )}
      </div>

      <div className="p-5 bg-pn-off-white-base text-pn-black-base w-full">
        <span className="small-print">Didn’t get the email?</span>
        <button
          type="button"
          className="small-print-link px-[2px]"
          onClick={handleResend}
        >
          Send again
        </button>{" "}
        <span className="small-print">or try</span>
        <button
          type="button"
          className="small-print-link px-[2px]"
          onClick={resetFlow}
        >
          another address
        </button>
      </div>

      <button
        type="submit"
        className="w-full h-[3.125rem] rounded-[1.5625rem] border border-pn-black-base text-pn-black-base bg-pn-white-base
          hover:bg-pn-white-hover disabled:bg-pn-white-hover"
        disabled={isSubmitting}
      >
        Verify Code
      </button>

      {apiError && <p className="text-pn-red-base">{apiError}</p>}
    </form>
  );
};
