import { googleSignInAction } from "./googleSignInAction";
import Image from "next/image";

export function GoogleSignInForm() {
  return (
    <form
      action={googleSignInAction}
      className="flex justify-center items-center self-stretch"
    >
      <button
        className="flex justify-center items-center self-stretch h-[3.125rem] flex-1 space-x-[0.625rem]
          rounded-[1.5625rem] border border-solid border-pn-black-base hover:bg-pn-white-hover transition-all ease-in-expo"
      >
        <Image
          src="/icons/google.svg"
          alt="Google"
          width={20}
          height={20}
          sizes="20px"
        />
        <p className="text-pn-black-base paragraph-bold">
          Continue with Google
        </p>
      </button>
    </form>
  );
}
