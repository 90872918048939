"use client";

import Script from "next/script";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";

type AppleCustomEventType = {
  detail: {
    authorization: {
      code: string;
      id_token: string;
      state: string;
      // Name is only sent once from Apple Servers
      user?: {
        name?: {
          firstName: string;
          lastName: string;
        };
        email: string;
      };
    };
  };
};

function getRandomHex(size: number) {
  const array = new Uint8Array(size);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
}

const START_AUTH_FLOW_ENDPOINT = "/api/auth/apple/start-auth-flow";

export function AppleSignInForm() {
  const appleSignInRef = useRef<HTMLButtonElement>(
    null as unknown as HTMLButtonElement
  );
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (appleSignInRef.current) {
      setShowContent(true);
    }

    // Clean up
    return () => {
      document.removeEventListener("AppleIDSignInOnSuccess", () => {});
      document.removeEventListener("AppleIDSignInOnFailure", () => {});
    };
  }, []);

  // Generate state and nonce when the component mounts
  const handleLoad = () => {
    // Generate state and nonce
    const state = getRandomHex(16);
    const nonce = getRandomHex(16);

    // // Store state and nonce in local storage or cookies
    localStorage.setItem("apple_auth_state", state);
    localStorage.setItem("apple_auth_nonce", nonce);

    // Initialize Apple Sign-In
    // eslint-disable-next-line no-undef
    // @ts-ignore
    AppleID.auth.init({
      clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
      scope: "name email",
      usePopup: false,
      redirectURI: `${process.env.NEXT_PUBLIC_SITE_URL}/api/auth/apple/callback`,
      state: state,
      nonce: nonce,
    });

    // Render the Apple Sign-In button
    appleSignInRef.current.innerHTML = "";

    setShowContent(true);

    // Listen for authorization success.
    document.addEventListener("AppleIDSignInOnSuccess", (event: any) => {
      // Handle successful response.
      console.log("Sign in successful");
      console.log(event);
    });

    // Listen for authorization failures.
    document.addEventListener("AppleIDSignInOnFailure", (event: any) => {
      // Handle error.
      console.log("Sign in failed");
      console.log(event);
    });
  };

  const handleClick = async () => {
    // Get state and nonce from local storage or cookies
    const state = localStorage.getItem("apple_auth_state");
    const nonce = localStorage.getItem("apple_auth_nonce");

    // Send state and nonce to the server
    const response = await fetch(START_AUTH_FLOW_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state,
        nonce,
      }),
    });

    if (!response.ok) {
      console.error("Error starting Apple auth flow");
      return;
    }

    appleSignInRef.current?.click();
  };

  return (
    <>
      <Script
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        strategy="afterInteractive"
        onReady={handleLoad}
      />

      {/* AppleJS button comes with a styling, so we hide it */}
      <button
        ref={appleSignInRef}
        id="appleid-signin"
        data-type="sign in"
        data-color="white"
        className="hidden"
      ></button>

      <button
        className="flex justify-center items-center self-stretch h-[3.125rem] flex-1 space-x-[0.625rem] rounded-[1.5625rem] border border-solid border-pn-black-base hover:bg-pn-white-hover transition-all ease-in-expo"
        onClick={handleClick}
      >
        <div className="flex justify-center items-center space-x-[0.625rem] h-[3.125rem]">
          <Image
            src="/icons/apple.svg"
            alt="Apple"
            width={16}
            height={20}
            sizes="16px"
            className="-mt-1"
          />
          <p className="text-pn-black-base paragraph-bold">
            Continue with Apple
          </p>
        </div>
      </button>
    </>
  );
}
