"use client";

import { useSelectedLayoutSegments } from "next/navigation";
import Image from "next/image";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import clsx from "clsx";
import Link from "next/link";
import { useCompanyData } from "@/hooks/useCompanyData";

const SignUpFormSchema = yup
  .object({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please fill in your email"),
    terms: yup
      .bool() // use bool instead of boolean
      .oneOf([true], "You must accept the terms and conditions"),
  })
  .required();

type FormType = yup.InferType<typeof SignUpFormSchema>;

export function SignUpForm() {
  const [showSuccessPage, setShowSuccessPage] = React.useState(false);
  const [error, setError] = React.useState("");
  const companyData = useCompanyData();

  const segments = useSelectedLayoutSegments();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignUpFormSchema),
  });

  // Submit Handler...
  const onSubmit = async (data: FormType) => {
    setError(""); // Reset Error

    const dataFields = [
      {
        key: "PN",
        value: "YES",
      },
      {
        key: "PN_SOURCE",
        value: "PN_WEB",
      },
    ];

    const formData = new FormData();

    formData.append("email", data.email);
    formData.append("optInType", "single");
    formData.append("emailType", "Html");

    // Append the dataFields array as a JSON string
    formData.append("dataFields", JSON.stringify(dataFields));

    const reqUrl = `/api/v1/dot-digital/newsletter-subscribe/?id=${companyData?.company_data.dot_digital_newsletter_id}`;

    try {
      const response = await fetch(reqUrl, {
        method: "POST",
        body: formData,
      }).then((res) => res.json());

      if (!response.error) {
        setShowSuccessPage(true);
      } else {
        setError(response.error || "An error occurred while subscribing.");
      }
    } catch (error) {
      console.error("Failed to fetch cart:", error);
      setError("An unexpected error occurred.");
    }
  };

  // Don't display Footer form on auth pages
  if (segments.includes("(auth)")) {
    return <></>;
  }

  return (
    <div className="min-h-48 w-full bg-pn-news-base relative">
      {/*  Background Image  */}
      <Image
        src={"/newsletter-signup.png"}
        alt={""}
        fill
        className={"max-lg:hidden object-cover object-center z-0"}
      />

      <div className="wrapper text-white py-5 z-5 relative">
        <div className="content flex flex-col gap-3 xl:max-w-[55%]">
          <h2 className="h1 text-white">Sign up to Photography News Weekly</h2>
          <p className="font-semibold">
            Get a weekly dose of the latest news, reviews and inspiration direct
            to your email inbox
          </p>
        </div>

        {showSuccessPage ? (
          <>
            <div className="flex flex-wrap items-center gap-2.5 mt-7">
              <div className="icon">
                <Image
                  src={"/icons/email-success.svg"}
                  alt={""}
                  width={48}
                  height={48}
                />
              </div>

              <div className="confirmation success content">
                <h2>Subscription confirmed</h2>
                <p className="mt-1">
                  We’ll send you the the next instalment in this series every
                  week
                </p>
              </div>
            </div>

            <p className="mt-7">
              You may opt-out at any time.{" "}
              <a
                className="font-semibold underline"
                href={companyData?.company_data.terms_conditions_page?.url}
                target={companyData?.company_data.terms_conditions_page?.target}
              >
                Terms and conditions
              </a>{" "}
              and{" "}
              <a
                className="font-semibold underline"
                href={companyData?.company_data.privacy_policy_page?.url}
                target={companyData?.company_data.privacy_policy_page?.target}
              >
                Privacy Policy
              </a>
              .
            </p>
          </>
        ) : (
          <form
            className={`relative mt-7 flex items-center flex-wrap gap-5 lg:max-w-[65%]`}
            onSubmit={handleSubmit(onSubmit)}
            id={"footer-signup-form"}
          >
            {/* Email  */}
            <div className="email flex flex-col order-1 flex-1 grow-[2]">
              <label hidden htmlFor="email" className={clsx("text-sm")}>
                Email*
              </label>
              <input
                {...register("email")}
                placeholder={`Email address`}
                className={clsx(
                  "border-box text-pn-black-base h-12 flex items-center px-5 bg-white w-full",
                  "placeholder:text-pn-placeholder-base border border-solid outline outline-2 outline-transparent",
                  "hover:outline-pn-news-base/30 focus:outline-pn-news-base/30 focus:placeholder:text-transparent",
                  errors.email ? "border-pn-red-base" : "border-pn-black-base"
                )}
              />
              {errors.email && (
                <p className="text-sm mt-1 text-white">
                  {errors.email.message}
                </p>
              )}
            </div>

            {/*  Terms & Conditions  */}
            <div className="terms-conditions w-full flex flex-col justify-center order-2 md:order-3">
              <label
                htmlFor="footer-newsletter-terms"
                className={clsx("text-sm block leading-tight select-none")}
              >
                <input
                  {...register("terms")}
                  className="mr-2"
                  type={"checkbox"}
                  name={"terms"}
                  id={"footer-newsletter-terms"}
                />
                I agree to Photography News’{" "}
                <a
                  className="font-semibold underline"
                  href={companyData?.company_data.terms_conditions_page?.url}
                  target={
                    companyData?.company_data.terms_conditions_page?.target
                  }
                >
                  terms and conditions
                </a>
                . For information about how we use your data, see our{" "}
                <a
                  className="font-semibold underline"
                  href={companyData?.company_data.privacy_policy_page?.url}
                  target={companyData?.company_data.privacy_policy_page?.target}
                >
                  privacy policy
                </a>
              </label>
              {errors.terms && (
                <p className="text-sm mt-1 text-white">
                  {errors.terms.message}
                </p>
              )}
            </div>

            {/* Submit Button */}
            <div className="email flex flex-col flex-1 order-3 md:order-2 md:max-w-[170px]">
              <button className="btn btn-tertiary" type="submit">
                Subscribe
              </button>
              {errors.email && (
                <p className="text-sm mt-1 text-white invisible">
                  {errors.email.message}
                </p>
              )}
            </div>

            {/*  Form Error  */}
            <p
              className={`w-full order-4 bg-pn-red-base/90 px-2 form-error error ${error ? "flex" : "hidden"}`}
            >
              {error}
            </p>
          </form>
        )}
      </div>
    </div>
  );
}
