"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import { Steps } from "./steps";
import * as yup from "yup";
import React from "react";

type SignInFormValues = {
  email: string;
  password: string;
};

export type LoginResponse = {
  sessionID: string;
};

type SignInFormProps = {
  setSessionID: (sessionID: string) => void;
  setStep: (step: (typeof Steps)[keyof typeof Steps]) => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
};

const signInSchema = yup
  .object({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: yup.string().required("Please enter your password"),
  })
  .required();

export const SignInFormMobile = ({
  setSessionID,
  setStep,
  setEmail,
  setPassword,
}: SignInFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(signInSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [apiError, setApiError] = React.useState<string | null>(null);

  const onSubmit: SubmitHandler<SignInFormValues> = async (data) => {
    setIsSubmitting(true);
    const loginEndpoint = `/api/auth/sign-in-via-wp`;

    const formData = new FormData();

    // Append each field to the FormData object
    formData.append("email", data.email);
    formData.append("password", data.password);

    const response = await fetch(loginEndpoint, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const responseData = (await response.json()) as { error: string };
      setApiError(responseData.error);
      setIsSubmitting(false);
      return;
    }

    const responseData = (await response.json()) as LoginResponse;

    setIsSubmitting(false);

    setPassword(data.password);
    setEmail(data.email);
    setSessionID(responseData.sessionID);
    setStep(Steps.mfa);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-start space-y-5 w-full"
    >
      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="email" className="text-pn-black-base">
          Email
        </label>
        <input
          type="email"
          id="email"
          {...register("email")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`,
          )}
          placeholder="Email"
        />
        {errors.email && (
          <p className="text-pn-red-base">{errors.email.message}</p>
        )}
      </div>

      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="password" className="text-pn-black-base">
          Password
        </label>
        <input
          type="password"
          id="password"
          {...register("password")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`,
          )}
          placeholder="Password"
        />
        {errors.password && (
          <p className="text-pn-red-base">{errors.password.message}</p>
        )}
      </div>

      <button
        onClick={() => setStep(Steps.resetPassword)}
        className="small-print-link text-pn-black-base"
        type="button"
      >
        Reset Password
      </button>
      <button
        type="submit"
        className="w-full h-[3.125rem] rounded-[1.5625rem] border border-pn-black-base text-pn-white-base bg-pn-black-base
          hover:bg-pn-black-hover disabled:bg-pn-black-hover"
        disabled={isSubmitting}
      >
        Sign in
      </button>

      {apiError && <p className="text-pn-red-base">{apiError}</p>}

      <div className="flex flex-wrap items-center w-full p-5 bg-pn-off-white-base">
        <p className="text-pn-black-base small-print">
          Want to sign in with a social account?
        </p>
        <button
          onClick={() => setStep(Steps.default)}
          className="text-pn-black-base paragraph-link px-[2px]"
          type="button"
        >
          Go back
        </button>
      </div>
    </form>
  );
};
